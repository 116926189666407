<template>
  <div v-if="this.pageData && this.testiPagina.length>0">
    <div class="main-container contatti">
      <header-intro :pagedata="pageData"></header-intro>
      <div class="main-content">
        <div class="container">
          <div class="content">
            <div class="title">
              <h2>{{ this.testiPagina[0].attributes.field_ui_text_titolo.value }}</h2>
            </div>
          </div>

          <div class="content comune" v-html="this.testiPagina[0].attributes.field_text_with_summary.value">
          </div>

          <br>

          <div class="social">
            <p>{{ this.$store.state.labelData[this.lang].contatti.social }}</p>

            <div>
              <a target="_blank" class="fb" href="https://www.facebook.com/comunesancanzian/">
                <img src="../assets/fb_icon.svg" width="30" alt="">facebook
              </a>
              <a target="_blank" class="in" href="https://www.instagram.com/explore/locations/104808913/comune-di-san-canzian-disonzo/?hl=it">
                <img src="../assets/in_icon.svg" width="30" alt="">instagram
              </a>
            </div>
            <hr>
            <p>{{ this.$store.state.labelData[this.lang].contatti.newsletter }} <a href="http://www.comune.sancanziandisonzo.go.it/html/italiano/newsletter.html">
              {{ this.lang=='de' ? 'Newsletter' : 'newsletter' }}
            </a></p>
          </div>
        </div>

        <div class="container">
          <div class="content">
            <div class="title">
              <h2>{{ this.testiPagina[1].attributes.field_ui_text_titolo.value }}</h2>
            </div>
          </div>

          <div class="content antiquarium" v-html="this.testiPagina[1].attributes.field_text_with_summary.value">
          </div>

        </div>
        <div class="container fullwidth">
          <!-- <div class="dark-banner">

            <div>
              {{ this.$store.state.labelData[this.lang].contatti.restrizioni }}<br>
              <span>{{ this.$store.state.labelData[this.lang].contatti.temp }}</span>
            </div>

          </div> -->
        </div>
      </div>
    </div>
    
    <!--<div class="main-container contatti2" id="aderisci">
      <div class="main-content">
        <form @submit.prevent>
          <ul class="container">
            <div class="contact-form">

              <h2>{{ this.testiPagina[2].attributes.field_ui_text_titolo.value }}</h2>
              <p>{{ this.testiPagina[2].attributes.field_text_with_summary.summary }}</p>

              <li class="campo">
                <label for="fullname">{{ this.$store.state.labelData[this.lang].formlabels.nome }}</label>
                <input type="text" name="fullname" v-model="formData.fullname">
              </li>
              <li class="campo">
                <label for="email">{{ this.$store.state.labelData[this.lang].formlabels.email }}</label>
                <input type="email" name="email" v-model="formData.email">
              </li>
              <li class="campo">
                <label for="msg">{{ this.$store.state.labelData[this.lang].formlabels.scrivi }}</label>
                <textarea name="msg" rows="10" v-model="formData.message"></textarea>
              </li>

              <div class="campo buttons">
                <vue-recaptcha ref="recaptcha" :sitekey="sitekey"
                  @verify="onverify" @error="onerror" @expired="onexpired"
                ></vue-recaptcha>
                <button class="btn dark-bg" @click="submit">
                  <span>{{ this.$store.state.labelData[this.lang].invia_button.toUpperCase() }}</span>
                </button>
              </div>

            </div>
          </ul>
        </form>
      </div>
    </div>-->

    <br><br><br><br>
    
  </div>
</template>

<script>
//import { VueRecaptcha } from 'vue-recaptcha'
import HeaderIntro from '../components/HeaderIntro'
import {
  fetchSinglePage,
  getTranslation
} from '../libs/drupalClient'
export default {
  name: 'contatti',
  components: {
    HeaderIntro,
    //VueRecaptcha
  },
  data() {
    return {
      pageData: null,
      formData: {
        fullname: "",
        email: "",
        message: "",
        robot: false
      },
      currentPageID: "d329b7f7-f83a-4849-a5a2-4ac999dc8546",
      //sitekey: "6LdCSEseAAAAAFXNI8QMlF1buv1vLITy7QLYvN-R"
    }
  },
  metaInfo() {
    return {
      title: this.pagemetatitle,
      meta: [
        {
          property: 'og:title',
          content: this.pagemetatitle
        }
      ]
    }
  },
  computed: {
    pagemetatitle() {
      if(this.lang == 'it') return 'Contatti'
      else if(this.lang=='de') return 'Kontakte'
      else return 'Contacts'
    },
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    testiPagina() {
      return this.pageData.field_ref_ui_text.filter(f => f.attributes.field_tipo == 'Testo pagina')
    },
    /* hash() {
      return this.$route.hash
    } */
  },
  async mounted() {
    await fetchSinglePage(this.currentPageID, this.lang)
    .then(res => this.pageData = res)
    
    /* if(this.hash) {
      await setTimeout(() => {
        window.scrollTo({
          top: document.querySelector(this.hash).getBoundingClientRect().top - 120,
          behavior: 'smooth'
        })
      }, 200)
    } */
  },
  methods: {
    /* onverify() {
      console.log('recaptcha - VERIFIED')
      this.formData.robot = true
    },
    onexpired() {
      console.log('recaptcha - EXPIRED')
      this.formData.robot = false
    },
    onerror() {
      console.log('recaptcha - ERROR')
      this.formData.robot = false
    },
    submit() {
      if(this.formData.robot) {
        //SUBMIT FORM
      }
    } */
  },
  destroyed() {
    this.formData.robot = false
  },
  watch: {
    lang() {
      getTranslation(this.$route.name, this.lang)
      .then(res => this.$router.push({ path: res }))

      //this.onexpired()

      fetchSinglePage(this.currentPageID, this.lang)
      .then(res => this.pageData = res)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

header { margin-bottom: 0rem; }

.social {
  > div {
    display: flex;
    flex-direction: column;
    a {
      display: flex;
      margin-bottom: 20px;
      text-decoration: none;
      img {
        margin-top: -2px;
        margin-right: 10px;
      }
    }
  }
}

//managin margins on 2 sections of the page
.main-container.contatti .main-content {
  margin-top: 0;
  margin-bottom: 2rem;
}

.main-container.contatti2 .main-content {
  margin-top: 6rem;

  position: relative;

  &:before {
    content: "";
    bottom: 0;
    right: 0;
    display: none;
    position: absolute;
    background: url(/img/contatti-deco.1b5199b4.png);
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-position: right top;
    z-index: -3;
    background-size: contain;
  }
}

.contatti2 .main-content { padding-bottom: 2rem; }
.contact-form { max-width: 600px; }

//first section style
.main-container.contatti {
  background-color: #f5f5f7;
  z-index: 0;
  position: relative;
  transform: skewY(-5deg);

  header {
    transform: skewY(0deg);
    position: relative;
    z-index: 5;
  }

  .main-content {
    transform: skewY(5deg);

    .container.fullwidth.imagewrapper {
      transform: skewY(-5deg);
      position: relative;
      z-index: -1;
      padding-bottom: 2rem;

      .content {
        overflow: hidden;
        figure { transform: skewY(5deg) scale(1.3) translateY(2rem); }
      }
    }

    .container.fullwidth.cutimage {
      clip-path: polygon(0 0, 100% 0%, 100% 78%, 0 92%);
      position: relative;
      z-index: -1;
      padding-bottom: 1rem;
    }
  }
}

//basic paragraph info style
.container .content .info,
.container .content ::v-deep ul {
  width: 100%;

  p span, li span {
    color: $primaryColor;
    font-weight: 700;
  }

  p, li {
    position: relative;
    padding-left: 3rem;
    word-wrap: break-word;

    &::before {
      content: '';
      background-size: contain;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 10px;
    }
  }
}

//COMMON ICONS IN THE TWO LISTS OF CONTACTS
.container .content {
  ::v-deep ul:first-child li {
    &:first-child::before { background-image: url(../assets/phone.svg); }
    &:nth-child(2)::before { background-image: url(../assets/location.svg); }
  }

  ::v-deep ul:nth-child(2) li {
    &:first-child::before { background-image: url(../assets/clock.svg); }
  }
}

//ICONS PLACED BASED ON POSITION IN THE LIST,
//NOT BASED ON CLASSES BECAUSE OF DRUPAL WYSIWYG
.container .content.comune {
  ::v-deep ul:first-child li {
    &:nth-child(3)::before { background-image: url(../assets/email.svg); }

    &:nth-child(4)::before { background-image: url(../assets/website.svg); }
  }
}

.container .content.antiquarium {
  ::v-deep ul:first-child li {
    &:nth-child(3)::before { background-image: url(../assets/ticket.svg); }
  }

  ::v-deep ul:nth-child(2) li {
    &:nth-child(2)::before { background-image: url(../assets/calendar.svg); }
  }
}

.campo {
  display: flex;
  flex-direction: column;

  label {
    color: $primaryColor;
    font-weight: 700;
    margin: 0;
  }

  input,
  textarea {
    background-color: #ededed;
    outline: none;
    border: 1px #999 solid;
    line-height: 1.5rem;
    color: #000;
    padding: .5rem;
    font-size: 1rem;

    &:focus { background-color: #fff; }
  }

  input { height: 2rem; }

  .btn {
    position: relative;
    padding: 24px 14px 24px 34px;
    height: unset;
    width: 100%;
    max-width: 300px;
    border: none;
    outline: none;

    span { position: relative; }

    span::before {
      position: absolute;
      display: block;
      content: '';
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../assets/send.svg);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

//help/error banner
.dark-banner {
  background-color: $primaryColor;
  color: #fff;
  text-align: center;
  padding: 6rem 2rem 1rem 2rem;
  position: relative;
  margin-top: 2rem;
  margin: 40px 15px 0 15px;

  font-size: 14px;

  span {
    font-weight: 700;
    width: 100%;
    display: block;
    font-size: 1rem;
    word-wrap: break-word;
  }

  div { position: relative; }

  > div::before {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0rem;
    //top: 50%;
    top: -70px;
    transform: translateX(-50%);
    background-image: url(../assets/warning_white.svg);
    left: 50%;
  }
}

figure {
  margin: 0;
  img { width: 100%; }
}

figcaption {
  font-size: 0.875rem;
  color: #8E8E8F;
}

.btn {
  text-decoration: none;
  text-transform: uppercase;
}

ul li { padding: 0.5rem 0rem 0.5rem 0rem; }

.buttons { text-align: center; }

@media (min-width: 400px) {
  .campo .btn::before { left: 2rem; }
}

@media (min-width: 768px) {
  .social {
    > div {
      display: flex;
      flex-direction: row;
      a { margin-right: 40px; }
    }
  }

  .dark-banner>div::before {
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0rem;
    top: 50%;
    transform: translateX(0%) translateY(-50%);
    left: -3em;
  }

  .content.comune {
    flex-direction: column;
    ::v-deep ul:last-child { margin-top: 2rem; }
  }

  h2 { margin-top: 3rem; }

  //mantain fullwidth images contained
  .container.fullwidth.imagewrapper {
    padding-bottom: 3rem;
    .content {
      display: block;
      height: 400px;
    }
  }

  .container.fullwidth.cutimage { display: none; }

  .dark-banner {
    padding: 20px 20px 20px 70px;
    span { font-size: 1.75rem; }
    &::before {
      left: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    & > div { width: 46%; }
  }
}

@media (min-width: 992px) {
  .dark-banner { max-width: 500px; }
}

@media (min-width:1024px) {
  .main-container.contatti {
    overflow: hidden;
    padding-top: 90px;
    margin-top: -90px;
  }

  .main-container.contatti .main-content { padding-bottom: 240px; }

  .main-container.contatti .main-content {
    background: url(/img/contatti-deco-2.048353a5.png), url(/img/contatti-deco-map.488015a0.jpg);
    background-repeat: no-repeat;
    background-position: bottom right, right top;
    background-size: 25%, 68%;
    padding-top: 8rem;
    margin-top: -5rem;
    padding-bottom: 50px;
  }

  .main-container.contatti2 .main-content:before { display: block; }
}


@media (min-width:1200px) {
  .main-container.contatti2 .main-content {
    &:before {
      display: block;
      height: 400px;
    }
  }

  .main-content .container .content { max-width: 800px; }
}

@media (max-width: 576px) {
  form ul li { padding: 0.5rem 0rem 0.5rem 0rem; }
}
</style>